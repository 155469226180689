<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">

        <v-toolbar
          class="mb-1"
          flat
          rounded
        >
          <v-toolbar-title><v-icon left>mdi-bank-outline</v-icon>Laws and Regulations</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            dense
            rounded
            outlined
            class="d-none d-sm-flex"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            :class="{ 'v-input--dense': !$vuetify.breakpoint.mdAndUp }"
          ></v-text-field>
        </v-toolbar>

         <v-card-title class="d-flex d-sm-none align-center flex-wrap">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            outlined
            dense
            rounded
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </v-card-title>

        <v-data-iterator
          :items="formattedLaws"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          no-data-text=""
          no-results-text=""
          hide-default-footer
        >
          <template v-slot:default="{ items, isLoading }">
            <v-row>
              <template v-if="isLoading">
                <v-col
                  cols="12"
                  md="6"
                  v-for="n in itemsPerPage"
                  :key="n"
                >
                  <v-skeleton-loader type="card" class="mb-4"></v-skeleton-loader>
                </v-col>
              </template>

              <template v-else>
                <v-col
                  v-for="law in items"
                  :key="law.id"
                  cols="12"
                  md="6"
                >
                  <v-card style="min-height: 100%;" class="justify-center position-relative" elevation="3">
                    <v-img
                      v-if="lawImage"
                      alt="Protected"
                      :src="lawImage"
                      contain
                      cover
                      no-repeat
                      position="right"
                      :style="{
                        opacity: 0.5,
                        position: 'absolute',
                        top: '10%',
                        right: '2%',
                        bottom: '10%',
                        zIndex: 0,
                      }"
                    ></v-img>
                    <v-chip
                      label
                      small
                      color="secondary"
                      class="text-overline ml-2 my-2"
                    >
                      {{ law.lawTag }}
                    </v-chip>
                    <v-chip
                      v-if="getLawCode(law.lawTag, law.lawNo)"
                      label
                      small
                      color="primary"
                      class="text-overline ml-1 my-2"
                    >
                      {{ getLawCode(law.lawTag, law.lawNo) }}
                    </v-chip>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-right">
                          {{ law.approvalDate }}
                        </v-list-item-subtitle>
                        <v-list-item-title 
                          class="text-h5 mb-1" 
                          style="white-space: normal; 
                          word-break: break-word;">
                          {{ law.officialTitle }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ law?.longTitle ? law.longTitle : law.aka }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mr-3"
                            fab
                            small
                            color="primary"
                            elevation="7"
                            raised
                            v-bind="attrs"
                            v-on="on"
                            @click="lawView(law.id)"
                          >
                            <v-icon small>mdi-book-open-page-variant-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Open Law</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </template>

          <template v-slot:no-results>
            <v-empty-state
              icon="mdi-alert-circle-outline"
              title="No matching laws found"
              description="Try a different search term."
            />
          </template>

          <template v-slot:no-data>
            <v-empty-state
              icon="mdi-alert-circle-outline"
              title="No laws have been published"
              description="Your law collection is currently empty."
            />
          </template>

          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="center">
              <v-col cols="12" md="8">
                <v-row align="center" justify="center">
                  <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    :total-visible="5"
                    circle
                    color="primary"
                  ></v-pagination>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <v-snackbar 
          :color="snackColor" 
          v-model="snackbar" 
          :timeout="2000"
        >{{ response }}</v-snackbar>
      </v-col>
    </v-row>
   
  </v-container>
</template>

<script>
import moment from "moment"; 
import { mapGetters, mapActions } from "vuex";
import useLocalStorage from '@/composables/useLocalStorage';
import VEmptyState from '../VEmptyState.vue';

export default {
  data() {
    return {
      search: '',
      itemsPerPage: 5,
      page: 1,
      snackbar: false,
      snackColor: 'success',
      response: '',
      lawImage: null,
      isLoading: true,
    };
  },
  created () {
    this.fetchLaws().then(() => {
      this.isLoading = false;
    });
    const lawImageStorage = useLocalStorage(null, "lawImage");
    this.lawImage = lawImageStorage.get();
  },
  components: {
    VEmptyState,
  },
  computed: {
    ...mapGetters(["getLaws"]),
    formattedLaws() {
      return (this.getLaws || []).map(law => {
        let formattedDate = 'N/A';
        if (law.approvalDate) {
          if (law.approvalDate.toDate) {
            formattedDate = moment(law.approvalDate.toDate()).format('LL');
          } else if (law.approvalDate instanceof Date) {
            formattedDate = moment(law.approvalDate).format('LL');
          } else if (typeof law.approvalDate === 'string') {
            formattedDate = moment(law.approvalDate).format('LL');
          }
        }
        return {
          ...law,
          approvalDate: formattedDate,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.getLaws.length / this.itemsPerPage);
    },
  },
  methods: {
    ...mapActions(["fetchLaws"]),
    getLawCode(lawTag, lawNo) {
      const lawTagMap = {
        "Republic Act": "RA",
        "Executive Order": "EO",
        "Presidential Decree": "PD",
        "Batas Pambansa": "BP",
        "Letters of Instruction": "LOI",
        "Commonwealth Acts": "CA",
        "Acts of Legislature": "Act"
      };
      const prefix = lawTagMap[lawTag] || "";
      return lawNo ? `${prefix} ${lawNo}` : "";
    },
    lawView(lawId) {
      this.$router.push({ name: 'laws-and-regulations', params: { id: lawId } });
    },
  },
};
</script>
